<template>
  <div>
    <b-card>
      <!-- Filter Form -->
      <div class="d-flex align-items-start filter-form">
        <div>
          <b-form
            class="mb-2"
            inline
          >

            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.campaign_name"
              placeholder="Campaign Name"
            ></b-form-input>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.client_id"
              :options="brandList"
              value-field="client_id"
              text-field="company_name"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Select Brand</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.web_show"
              :options="campaigntype"
              value-field="value"
              text-field="label"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Campaign Mode</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.promo_platform"
              :options="platformOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Platform</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.campaign_status"
              :options="campaignstatusOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Campaign Status</b-form-select-option>
              </template>
            </b-form-select>
            <b-form-select
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="filter.by_logged"
              value-field="value"
              text-field="label"
              :options="campaignLoggedOptions"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >Campaign Type</b-form-select-option>
              </template>
            </b-form-select>
            <b-button
              pill
              class="mt-lg-0 mt-md-2 mr-sm-2"
              variant="primary"
              @click="search()"
            >Search</b-button>
            <b-button
              pill
              class="mt-2"
              variant="primary"
              @click="resetFilter()"
            >Reset</b-button>
          </b-form>
        </div>
        <b-overlay
          :show="exportLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block ml-auto"
          @hidden="!exportLoading"
        >
          <b-dropdown
            class="ml-auto"
            variant="outline-white"
            right
            no-caret
          >
            <template #button-content>
              <b-icon icon="list"></b-icon>
            </template>
            <b-dropdown-item @click="exportdata()">
              <b-icon icon="download"></b-icon>
              Export
            </b-dropdown-item>
            <b-dropdown-item @click="addmodal">
              <b-icon icon="person-plus"></b-icon>
              Add Campaign
            </b-dropdown-item>
          </b-dropdown>
        </b-overlay>
      </div>

      <!-- <hr /> -->

      <!-- Data Table -->
      <div
        class="d-block text-center"
        v-if="!tabledata.length && !loading"
      >
        <img
          src="@/assets/images/no-data.png"
          width="150"
        />
        <h5 class="mt-2">No Record Found</h5>
      </div>
      <!-- <b-table
        :items="tabledata"
        :fields="fields"
        id="tabledata"
        :busy="loading"
        head-variant="light"
        responsive
        hover
        v-else
      >
        <template #table-busy>
          <div class="text-center text-primary mt-5 mb-1">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <div style="min-width: 160px;">
            <div @click="opencampaignDetail(data.item.campaign_id, data.item.promo_platform, data.item.pending_applications_brand || 0, data.item.pending_applications_influencer || 0)" class="innerdiv">
              {{data.item.campaign_name}}
            </div>
            <b-badge v-if="data.item.totalAssign > 0" pill variant="success" class="mr-1">
              <b-icon icon="check-circle"></b-icon>
              Assigned {{data.item.totalAssign}}
            </b-badge>
            <b-badge v-if="data.item.pending_applications_brand + data.item.pending_applications_influencer > 0" pill variant="warning">
              <b-icon icon="exclamation-circle"></b-icon>
              Pending {{data.item.pending_applications_brand + data.item.pending_applications_influencer }}
            </b-badge>
          </div>
        </template>
        <template #cell(category)="data">
          <div style="max-width: 120px;">
            {{ data.value || 'NA' }}
          </div>
        </template>
        <template #cell(language)="data">
          <div style="max-width: 120px;">
            {{ data.value || 'NA' }}
          </div>
        </template>
        <template #cell(gender)="data">
          {{ data.value || 'NA'}}
        </template>
        <template #cell(promo_platform)="data">
          {{ data.value | platform}}
        </template>
        <template #cell(campaign_type)="data">
          {{ data.value | campaignType}}
        </template>
        <template #cell(content)="data">
          <div v-if="data.item.promo_platform===1">
            {{ data.item.content_type | youtube}}
          </div>
          <div v-else>{{ data.item.content_type | instagram}} </div>
        </template>
        <template #cell(client_id)="data">
          {{ brandName(data.value)}}
        </template>
        <template #cell(budget)="data">
          {{ data.value | formatNumber}}
        </template>
        <template #cell(campaign_status)="data">
          <div style="min-width: 80px;">
            <span class="text-warning" v-if="data.value === 0">
              <b-icon icon="exclamation-circle"></b-icon>
              {{ data.value | campaignStatus}}
            </span>
            <span class="text-success" v-if="data.value === 1">
              <b-icon icon="check-circle"></b-icon>
              {{ data.value | campaignStatus}}
            </span>
            <span class="text-danger" v-if="data.value === 2">
              <b-icon icon="x-circle"></b-icon>
              {{ data.value | campaignStatus}}
            </span>
            <span class="text-info" v-if="data.value === 4">
              <b-icon icon="check-circle"></b-icon>
              {{ data.value | campaignStatus}}
            </span>
          </div>
        </template>
        <template #cell(add_time)="data">
          <div style="min-width: 80px;">
            {{ data.value | dateFormat}}
          </div>
        </template>
        <template #cell(live)="data">
          <b-form-checkbox
            v-model="data.item.web_show"
            name="check-button"
            switch
            @change="webshowUpdate(data.item)"
          >
          </b-form-checkbox>
        </template>

        <template #cell(action)="data">
          <b-dropdown
            variant="outline-white"
            no-caret
          >
            <template #button-content>
              <b-icon icon="three-dots-vertical"></b-icon>
            </template>
            <b-dropdown-item @click="editmodal(data.item)">
              <b-icon icon="pencil-square"></b-icon>
              Edit
            </b-dropdown-item>
            <b-dropdown-item @click="deleteCampaign(data.item.campaign_id)">
              <b-icon icon="trash"></b-icon>
              Delete
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table> -->

      <!-- AddCampaign Modal  -->
      <b-modal
        title="Add Campaign"
        ref="addModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="addchannelrules">
          <b-form>
            <b-form-group label="Campaign Name">
              <validation-provider
                #default="{ errors }"
                name="Campaign Name"
                rules="required"
              >
                <b-form-input
                  v-model="addCampaign.campaign_name"
                  placeholder="Campaign Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Brand">
              <validation-provider
                #default="{ errors }"
                name="Brand"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  value-field="client_id"
                  text-field="company_name"
                  v-model="addCampaign.client_id"
                  :options="brandList"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Brand</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Product Link">
              <validation-provider
                #default="{ errors }"
                name="Product Link"
                :rules="{regex: /[-a-zA-Z0-9@:%_/+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_/+.~#?&//=]*)?/}"
              >
                <b-form-input
                  v-model="addCampaign.product_link"
                  placeholder="Product Link"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Platform">
              <validation-provider
                #default="{ errors }"
                name="Platform"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  @change="changeplatform"
                  v-model="addCampaign.promo_platform"
                  :options="platformOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Platform</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Campaign Type">
              <validation-provider
                #default="{ errors }"
                name="Campaign Type"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="addCampaign.campaign_type"
                  :options="campaignTypeOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Campaign Type</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Content Type">
              <validation-provider
                #default="{ errors }"
                name="Content Type"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  :disabled="!addCampaign.promo_platform"
                  v-model="addCampaign.content_type"
                  :options="addCampaign.promo_platform === 1 ? youtubeOptions : instagramOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Content Type</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- <b-form-group label="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="addCampaign.category"
                  :state="errors.length > 0 ? false : null"
                  :options="categoryOptions"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select category</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <!-- <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="addCampaign.language"
                  :options="languageOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Language</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <!-- <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="addCampaign.gender"
                  :options="genderOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Gender</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group> -->
            <b-form-group label="Category">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <multiselect
                  v-model="addCampaign.category"
                  :disabled="!addCampaign.promo_platform"
                  :state="errors.length > 0 ? false : null"
                  :options="addCampaign.promo_platform === 1 ? categoryOptions : instaCategoryOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addCampaign.category && addCampaign.category.length ? addCampaign.category.length : 'Select' }} Category</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <multiselect
                  v-model="addCampaign.language"
                  :state="errors.length > 0 ? false : null"
                  :options="languageOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addCampaign.language && addCampaign.language.length? addCampaign.language.length : 'Select' }} Language</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <multiselect
                  v-model="addCampaign.gender"
                  :state="errors.length > 0 ? false : null"
                  :options="genderOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ addCampaign.gender && addCampaign.gender.length ? addCampaign.gender.length : 'Select' }} Gender</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Budget">
              <validation-provider
                #default="{ errors }"
                name="Budget"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="addCampaign.budget"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Budget"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Min Follower">
              <validation-provider
                #default="{ errors }"
                name="Min Follower"
                :rules="{ regex: /^[0-9]{0,12}$/ }"
              >
                <b-form-input
                  v-model="addCampaign.min_follower"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Min Follower"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Max Follower">
              <validation-provider
                #default="{ errors }"
                name="Max Follower"
                :rules="{ regex: /^[0-9]{0,12}$/ }"
              >
                <b-form-input
                  v-model="addCampaign.max_follower"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Max Follower"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Start date">
              <validation-provider
                #default="{ errors }"
                name="Start date"
              >
                <b-form-datepicker
                  v-model="addCampaign.start_date"
                  today-button
                  reset-button
                  close-button
                  placeholder="Select start date"
                  :state="errors.length > 0 ? false : null"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="End date">
              <validation-provider
                #default="{ errors }"
                name="End date"
              >
                <b-form-datepicker
                  v-model="addCampaign.end_date"
                  placeholder="Select end date"
                  today-button
                  reset-button
                  close-button
                  :state="errors.length > 0 ? false : null"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Campaign Desc">
              <validation-provider
                #default="{ errors }"
                name="Campaign Desc"
              >
                <b-form-textarea
                  v-model="addCampaign.campaign_desc"
                  placeholder="Campaign Desc"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Deliverable">
              <validation-provider
                #default="{ errors }"
                name="Deliverable"
              >
                <b-form-textarea
                  v-model="addCampaign.deliverable"
                  placeholder="Deliverable"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelAdd"
              >Cancel</b-button>
              <b-button
                pill
                class="mr-2"
                variant="primary"
                @click="resetCampaign"
              >Reset</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="addcampaign"
              >Add</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- EditCampaign Modal  -->
      <b-modal
        title="Edit Campaign"
        ref="editModal"
        :no-close-on-backdrop="true"
        hide-footer
        centered
      >
        <validation-observer ref="simpleRules">
          <b-form>

            <b-form-group label="Campaign Name">
              <validation-provider
                #default="{ errors }"
                name="Campaign Name"
                rules="required"
              >
                <b-form-input
                  v-model="editCampaign.campaign_name"
                  placeholder="Campaign Name"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Brand">
              <validation-provider
                #default="{ errors }"
                name="Brand"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  value-field="client_id"
                  text-field="company_name"
                  v-model="editCampaign.client_id"
                  :options="brandList"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Brand</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Product Link">
              <validation-provider
                #default="{ errors }"
                name="Product Link"
                :rules="{regex: /[-a-zA-Z0-9@:%_/+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_/+.~#?&//=]*)?/}"
              >
                <b-form-input
                  v-model="editCampaign.product_link"
                  placeholder="Product Link"
                  :state="errors.length > 0 ? false : null"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Platform">{{addCampaign.promo_platform}}
              <validation-provider
                #default="{ errors }"
                name="Platform"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  @change="changeplatform"
                  v-model="editCampaign.promo_platform"
                  :options="platformOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Platform</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Campaign Type">
              <validation-provider
                #default="{ errors }"
                name="Campaign Type"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  v-model="editCampaign.campaign_type"
                  :options="campaignTypeOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Campaign Type</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Content Type">
              <validation-provider
                #default="{ errors }"
                name="Content Type"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  :disabled="!editCampaign.promo_platform"
                  v-model="editCampaign.content_type"
                  :options="editCampaign.promo_platform === 1 ? youtubeOptions : instagramOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Content Type</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Category">{{editCampaign.platform }}
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <multiselect
                  v-model="editCampaign.category"
                  :disabled="!editCampaign.promo_platform"
                  :state="errors.length > 0 ? false : null"
                  :options="editCampaign.promo_platform === 1 ? categoryOptions : instaCategoryOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editCampaign.category && editCampaign.category.length ? editCampaign.category.length : 'Select' }} Category</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Language">
              <validation-provider
                #default="{ errors }"
                name="Language"
                rules="required"
              >
                <multiselect
                  v-model="editCampaign.language"
                  :state="errors.length > 0 ? false : null"
                  :options="languageOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editCampaign.language && editCampaign.language.length? editCampaign.language.length : 'Select' }} Language</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Gender">
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules="required"
              >
                <multiselect
                  v-model="editCampaign.gender"
                  :state="errors.length > 0 ? false : null"
                  :options="genderOptions"
                  multiple
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  placeholder=""
                  selectLabel=""
                  deselectLabel=""
                >
                  <template slot="selection">
                    <span class="multiselect__single">{{ editCampaign.gender && editCampaign.gender.length ? editCampaign.gender.length : 'Select' }} Gender</span>
                  </template>
                </multiselect>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Campaign Status">
              <validation-provider
                #default="{ errors }"
                name="Campaign Status"
                rules="required"
              >
                <b-form-select
                  class="mb-2 mr-sm-2 mb-sm-0"
                  disabled
                  v-model="editCampaign.campaign_status"
                  :options="campaignstatusOptions"
                  :state="errors.length > 0 ? false : null"
                > <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >Select Campaign Status</b-form-select-option>
                  </template>
                </b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Budget">
              <validation-provider
                #default="{ errors }"
                name="Budget"
                :rules="{ regex: /^[0-9]{0,10}$/ }"
              >
                <b-form-input
                  v-model="editCampaign.budget"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Budget"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Min Follower">
              <validation-provider
                #default="{ errors }"
                name="Min Follower"
                :rules="{ regex: /^[0-9]{0,12}$/ }"
              >
                <b-form-input
                  v-model="editCampaign.min_follower"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Min Follower"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Max Follower">
              <validation-provider
                #default="{ errors }"
                name="Max Follower"
                :rules="{ regex: /^[0-9]{0,12}$/ }"
              >
                <b-form-input
                  v-model="editCampaign.max_follower"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Max Follower"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Start date">
              <validation-provider
                #default="{ errors }"
                name="Start date"
              >
                <b-form-datepicker
                  v-model="editCampaign.start_date"
                  today-button
                  reset-button
                  close-button
                  placeholder="Select start date"
                  :state="errors.length > 0 ? false : null"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="End date">
              <validation-provider
                #default="{ errors }"
                name="End date"
              >
                <b-form-datepicker
                  v-model="editCampaign.end_date"
                  placeholder="Select end date"
                  today-button
                  reset-button
                  close-button
                  :state="errors.length > 0 ? false : null"
                ></b-form-datepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Campaign Desc">
              <validation-provider
                #default="{ errors }"
                name="Campaign Desc"
              >
                <b-form-textarea
                  v-model="editCampaign.campaign_desc"
                  placeholder="Campaign Desc"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Deliverable">
              <validation-provider
                #default="{ errors }"
                name="Deliverable"
              >
                <b-form-textarea
                  v-model="editCampaign.deliverable"
                  placeholder="Deliverable"
                  :state="errors.length > 0 ? false : null"
                ></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="d-flex justify-content-end mt-4">
              <b-button
                pill
                class="mr-2"
                variant="secondary"
                @click="cancelEdit"
              >Cancel</b-button>
              <b-button
                pill
                type="submit"
                variant="primary"
                @click.prevent="updateCampaign"
              >Update</b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-modal>
    </b-card>
    <!-- <b-overlay
      :show="loading"
      no-wrap
    >
    </b-overlay> -->
    <!-- <b-overlay
      :show="!loading"
      no-wrap
    > <template #overlay>
        <b-icon
          icon="three-dots"
          animation="cylon"
          font-scale="5"
        ></b-icon>
      </template>
    </b-overlay> -->
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="loading"
      style="height:50vh;color:#4c62e3"
    >
      <b-spinner></b-spinner>&nbsp;&nbsp;Loading...
    </div>
    <b-card
      v-else
      class="mt-3 infocard"
      v-for="item in tabledata"
      :key="item.campaign_id"
    >
      <div class="d-flex">
        <div>
          <h6 class="d-flex align-items-center">
            <b-icon
              variant="primary"
              :icon="`${item.promo_platform === 1 ? 'youtube' : 'instagram'}`"
              class="text-danger mr-2"
              font-scale="2.4"
            ></b-icon>
            <span
              class="title"
              @click="opencampaignDetail(item.campaign_id, item.promo_platform, item.pending_applications_influencer || 0, item.pending_applications_brand || 0, item.client_id)"
            >
              {{item.campaign_name}}
            </span>

            <span
              class="text-warning"
              v-if="item.campaign_status === 0"
            >
              <b-icon icon="exclamation-circle"></b-icon>
              {{ item.campaign_status | campaignStatus}}
            </span>
            <span
              class="text-success"
              v-if="item.campaign_status === 1"
            >
              <b-spinner
                variant="success"
                type="grow"
                label="Spinning"
                style="width: 0.4rem; height: 0.4rem;"
              ></b-spinner>
              {{ item.campaign_status | campaignStatus}}
            </span>
            <span
              class="text-danger"
              v-if="item.campaign_status === 2"
            >
              <b-icon icon="x-circle"></b-icon>
              {{ item.campaign_status | campaignStatus}}
            </span>
            <span
              class="text-info"
              v-if="item.campaign_status === 4"
            >
              <b-icon icon="check-circle"></b-icon>
              {{ item.campaign_status | campaignStatus}}
            </span>
          </h6>
          <p class="d-flex align-items-center">
            <small
              class="client"
              @click="openclientModal(item)"
            >
              <b-icon
                variant="primary"
                icon="person-circle"
              ></b-icon>
              {{brandName(item.client_id)}}
            </small>
            <small>
              <b-icon
                variant="primary"
                icon="calendar-check"
              ></b-icon>
              {{ item.add_time | dateFormat }}
            </small>
            <b-badge
              v-if="item.totalAssign > 0"
              pill
              style="color:white;background-color:teal;"
            >
              <b-icon icon="check-circle"></b-icon>
              Approved Channel {{item.totalAssign}}
            </b-badge>
            <b-badge
              v-if="(item.pending_applications_brand + item.pending_applications_influencer) > 0"
              pill
              variant="warning"
            >
              <b-icon icon="exclamation-circle"></b-icon>
              Pending Application {{item.pending_applications_brand + item.pending_applications_influencer }}
            </b-badge>
          </p>
        </div>
      </div>

      <div class="action d-flex">
        <p class="mt-1"><small>Live:</small> </p>
        <b-form-checkbox
          switch
          :disabled="item.campaign_status === 1 || item.campaign_status === 4 ? false : true"
          v-model="item.web_show"
          name="check-button"
          class="mt-1"
          @change="webshowUpdate(item)"
        >
        </b-form-checkbox>

        <b-button
          size="sm"
          variant="white text-primary"
          @click="editmodal(item)"
        >
          <b-icon icon="pencil-square"></b-icon>
        </b-button>
        <b-button
          size="sm"
          variant="text-primary"
          @click="deleteCampaign(item.campaign_id)"
        >
          <b-icon
            icon="trash"
            class="text-danger"
          ></b-icon>
        </b-button>
      </div>

      <b-row class="mt-3">
        <b-col cols="3">
          <strong>Brand Details</strong>
          <p>
            <span class="label">Brand: </span>
            <small>{{ item.company_name }}</small>
          </p>
          <p>
            <span class="label">Name: </span>
            <small>{{ item.name }}</small>
          </p>
          <p>
            <span class="label">Phone: </span>
            <small>{{ item.mobile }}</small>
          </p>
          <p>
            <span class="label">Email: </span>
            <small>{{ item.email }}</small>
          </p>
        </b-col>
        <b-col cols="3">
          <strong>Overview</strong>
          <p>
            <span class="label">Campaign Type: </span>
            <small>{{ item.campaign_type | campaignType }}</small>
          </p>
          <p>
            <span class="label">Content Type: </span>
            <small v-if="item.promo_platform === 1">
              {{ item.content_type | youtube}}
            </small>
            <small v-else>{{ item.content_type | instagram}} </small>
          </p>
          <p>
            <span class="label">Budget: </span>
            <small>{{ item.budget | formatNumber}}</small>
          </p>
          <p>
            <span class="label">Followers Range: </span>
            <small>{{ item.min_follower | formatNumber }} ~ {{ item.max_follower | formatNumber }}</small>
          </p>
        </b-col>
        <b-col cols="2">
          <p>
            <strong>Categories</strong> <br />
            <small v-if="item.category != '' && item.category != null">
              <span
                v-for="value in item.category.split(',')"
                :key="value"
              >
                {{ value }},
              </span>
            </small>
          </p>
        </b-col>
        <b-col cols="2">
          <p>
            <strong>Language</strong> <br />
            <small v-if="item.language != '' && item.language != null">
              <span
                v-for="value in item.language.split(',')"
                :key="value"
              >
                {{ value }},
              </span>
            </small>
          </p>
        </b-col>
        <b-col cols="2">
          <p>
            <strong>Gender</strong> <br />
            <small v-if="item.gender != '' && item.gender != null">
              <span
                v-for="value in item.gender.split(',')"
                :key="value"
              >
                {{ value }},
              </span>
            </small>
          </p>
        </b-col>
      </b-row>

    </b-card>
    <b-pagination
      v-if="total > 50 && !loading"
      v-model="currentPage"
      :total-rows="total"
      :per-page="perPage"
      class="mt-4"
      align="center"
      pills
      @input="handlePageClick"
    ></b-pagination>
    <b-modal
      title="Brand Info"
      ref="clientmodal"
      :no-close-on-backdrop="true"
      hide-footer
      width="20"
      centered
    >
      <div>
        <div class="d-flex">
          <div>Name:</div>
          <div class="name">&nbsp;&nbsp;&nbsp;&nbsp;{{clientData.name}}</div>
        </div>
        <div class="d-flex">
          <div>Mobile:</div>
          <div class="name">&nbsp;&nbsp;&nbsp;{{clientData.mobile}}</div>
        </div>
        <div class="d-flex">
          <div>Email:</div>
          <div class="name">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{clientData.email}}</div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import constantData from '@/helpers/constant'
import { campaignList, campaignDelete, campaignAdd, campaignUpdate, brandSelect, campaignStatus } from '@/api/campaign'
export default {
  data () {
    return {
      clientmodal: false,
      clientData: {},
      perPage: 50,
      currentPage: 1,
      editModal: false,
      addModal: false,
      tabledata: [],
      brandList: [],
      campaigntype: [{
        value: 0,
        label: 'Offline'
      },
      {
        value: 1,
        label: 'Live'
      }],
      loading: true,
      exportLoading: false,
      total: 0,
      filter: {
        campaign_name: null,
        client_id: null,
        promo_platform: null,
        web_show: null,
        campaign_status: null,
        p: 1,
        size: 50,
        by_logged: null
      },
      fields: [
        { key: 'index', label: 'Sr.' },
        { key: 'name', label: 'Campaign Name', stickyColumn: true },
        { key: 'client_id', label: 'Brand' },
        { key: 'campaign_status', label: 'Status' },
        { key: 'live', label: 'Live' },
        { key: 'promo_platform', label: 'Platform' },
        { key: 'category', label: 'Category' },
        { key: 'language', label: 'Language' },
        { key: 'gender', label: 'Gender' },
        { key: 'content', label: 'Content Type' },
        { key: 'campaign_type', label: 'Campaign Type' },
        { key: 'budget', label: 'Budget' },
        { key: 'add_time', label: 'Add Time' },
        'action'
      ],
      editCampaign: {
      },
      addCampaign: {
        client_id: null,
        campaign_name: null,
        product_link: null,
        category: null,
        language: null,
        gender: null,
        budget: null,
        min_follower: null,
        max_follower: null,
        promo_platform: null,
        campaign_type: null,
        content_type: null,
        campaign_desc: null,
        deliverable: null,
        start_date: null,
        end_date: null
      },
      campaignLoggedOptions: [{
        value: 0,
        label: 'Admin Create'
      }, {
        value: 1,
        label: 'Client Create'
      }],
      categoryOptions: constantData.category,
      instaCategoryOptions: constantData.instaCategoryList,
      genderOptions: constantData.genderList,
      languageOptions: constantData.language,
      platformOptions: constantData.platformList.map(item => {
        return { value: item.value, text: item.label }
      }),
      youtubeOptions: constantData.contentYoutube.map(item => {
        return { value: item.value, text: item.label }
      }),
      instagramOptions: constantData.contentInstagram.map(item => {
        return { value: item.value, text: item.label }
      }),
      campaignstatusOptions: constantData.campaignStatus.map(item => {
        return { value: item.value, text: item.label }
      }),
      campaignTypeOptions: constantData.campaignTypeList.map(item => {
        return { value: item.value, text: item.label }
      })
    }
  },
  mounted () {
    this.getBrand()
    this.getData()
  },
  methods: {
    openclientModal (item) {
      // this.clientData.name = item.name
      // this.clientData.mobile = item.mobile
      // this.clientData.email = item.email
      this.clientData = { ...item }
      this.$refs.clientmodal.show()
    },
    changeplatform () {
      this.editCampaign.content_type = null
      this.addCampaign.content_type = null
      this.editCampaign.category = null
      this.addCampaign.category = null
    },
    exportdata () {
      this.exportLoading = true
      var influencerWS = XLSX.utils.json_to_sheet(this.tabledata)
      var wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, influencerWS, 'CampaignData')
      setTimeout(() => {
        XLSX.writeFile(wb, 'Campaign.xlsx')
        this.exportLoading = false
      }, 2000)
    },
    resetFilter () {
      this.filter = {
        campaign_name: null,
        client_id: null,
        promo_platform: null,
        web_show: null,
        campaign_status: null,
        by_logged: null,
        p: 1,
        size: 50
      }
      this.currentPage = 1
      this.getBrand()
      this.getData()
    },
    resetCampaign () {
      this.addCampaign = {
        client_id: null,
        campaign_name: null,
        product_link: null,
        category: null,
        language: null,
        gender: null,
        budget: null,
        min_follower: null,
        max_follower: null,
        promo_platform: null,
        campaign_type: null,
        content_type: null,
        campaign_desc: null,
        deliverable: null,
        start_date: null,
        end_date: null
      }
    },
    addmodal () {
      this.$refs.addModal.show()
    },
    editmodal (item) {
      this.editCampaign = {
        client_id: item.client_id,
        campaign_id: item.campaign_id,
        campaign_name: item.campaign_name,
        product_link: item.product_link,
        category: item.category ? item.category.split(',') : null,
        language: item.language ? item.language.split(',') : null,
        gender: item.gender ? item.gender.split(',') : null,
        budget: item.budget,
        min_follower: item.min_follower,
        max_follower: item.max_follower,
        promo_platform: item.promo_platform,
        campaign_type: item.campaign_type,
        content_type: item.content_type,
        campaign_desc: item.campaign_desc,
        deliverable: item.deliverable,
        start_date: item.start_date ? this.dateFormat(item.start_date) : null,
        end_date: item.end_date ? this.dateFormat(item.end_date) : null,
        campaign_status: item.campaign_status
      }
      this.$refs.editModal.show()
    },
    dateFormat (date) {
      var d = new Date(date)
      var formattedDate = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`
      return formattedDate
    },
    cancelAdd () {
      this.resetCampaign()
      this.$refs.addModal.hide()
    },
    cancelEdit () {
      this.$refs.editModal.hide()
    },
    handlePageClick (pageNumber) {
      this.filter.p = pageNumber
      this.getData()
    },
    search () {
      this.currentPage = 1
      this.filter.p = 1
      this.getData()
    },
    opencampaignDetail (campaignId, platform, pendingApplicationsInfluencer, pendingApplicationsBrand, client) {
      // pendingApplicationsInfluencer, pendingApplicationsBrand
      this.$router.push({
        name: 'campaignDetails',
        params: {
          campaignId: campaignId,
          platform: platform
        },
        query: { pendingApplicationsInfluencer, pendingApplicationsBrand, client }
      })
    },
    webshowUpdate (item) {
      try {
        this.$bvModal.msgBoxConfirm(item.web_show ? 'Are are you sure want to live this campaign?' : 'Are are you sure want to offline this campaign?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            actionUser: window.localStorage.getItem('user-name'),
            campaign_id: item.campaign_id,
            web_show: item.web_show ? 1 : 0
          }
          if (value) {
            const response = await campaignStatus(data)
            if (response.succ) {
              this.$bvToast.toast(item.web_show ? 'Campaign Live successfully' : 'Campaign offline successfully', {
                title: 'Live Status',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Live Status error!',
                variant: 'danger',
                solid: true
              })
            }
          } else {
            item.web_show = !item.web_show
          }
        }).catch((error) => {
          console.log('cancel status error.', error)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async getData () {
      this.loading = true
      const response = await campaignList(this.filter)
      if (response.succ) {
        this.total = response.totaluser
        // this.tabledata = response.data
        this.tabledata = response.data.map(item => {
          item.web_show = !!item.web_show
          return item
        })
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
      this.loading = false
    },
    async getBrand () {
      this.loading = true
      const response = await brandSelect(this.filter)
      if (response.succ) {
        this.brandList = response.data
      } else {
        this.$bvToast.toast(response.msg, {
          title: 'Server error!',
          variant: 'danger',
          solid: true
        })
      }
    },
    brandName (value) {
      var str = value
      const brandNameArr = []
      const name = this.brandList.find(
        ar => ar.client_id === str
      )
      if (name) {
        brandNameArr.push(name.company_name)
      } else {
        brandNameArr.push('-')
      }
      return brandNameArr.join()
    },
    async deleteCampaign (campaignId) {
      try {
        this.$bvModal.msgBoxConfirm('Are are you sure want to delete this campaign?', {
          title: 'Please Confirm',
          cancelVariant: 'danger',
          okVariant: 'primary',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          size: 'sm',
          buttonSize: 'sm',
          hideHeaderClose: false,
          centered: true
        }).then(async (value) => {
          const data = {
            campaign_id: campaignId
          }
          if (value) {
            const response = await campaignDelete(data)
            if (response.succ) {
              this.$bvToast.toast('Campaign deleted successfully', {
                title: 'Deleted',
                variant: 'success',
                autoHideDelay: 2000,
                solid: true
              })
              this.tabledata.splice(this.tabledata.findIndex(a => a.campaign_id === campaignId), 1)
            } else {
              this.$bvToast.toast(response.msg, {
                title: 'Delete error!',
                variant: 'danger',
                solid: true
              })
            }
          }
        }).catch((error) => {
          console.log('cancel delete. error', error)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async updateCampaign () {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to update this campaign?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                client_id: this.editCampaign.client_id,
                campaign_id: this.editCampaign.campaign_id,
                campaign_name: this.editCampaign.campaign_name,
                product_link: this.editCampaign.product_link,
                gender: this.editCampaign.gender ? this.editCampaign.gender.join() : null,
                category: this.editCampaign.category ? this.editCampaign.category.join() : null,
                language: this.editCampaign.language ? this.editCampaign.language.join() : null,
                budget: this.editCampaign.budget,
                min_follower: this.editCampaign.min_follower,
                max_follower: this.editCampaign.max_follower,
                promo_platform: this.editCampaign.promo_platform,
                campaign_type: this.editCampaign.campaign_type,
                content_type: this.editCampaign.content_type,
                campaign_desc: this.editCampaign.campaign_desc,
                deliverable: this.editCampaign.deliverable,
                start_date: this.editCampaign.start_date,
                end_date: this.editCampaign.end_date,
                campaign_status: this.editCampaign.campaign_status
              }
              if (value) {
                const response = await campaignUpdate(data)
                if (response.succ) {
                  this.$bvToast.toast('Campaign updated successfully', {
                    title: 'Updated',
                    variant: 'success',
                    autoHideDelay: 1000,
                    solid: true
                  })
                  this.getData()
                  this.$refs.editModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Update error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch(() => {
              console.log('cancel update.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    },
    async addcampaign () {
      this.$refs.addchannelrules.validate().then(async success => {
        if (success) {
          try {
            this.$bvModal.msgBoxConfirm('Are are you sure want to add this campaign?', {
              title: 'Please Confirm',
              cancelVariant: 'danger',
              okVariant: 'primary',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              size: 'sm',
              buttonSize: 'sm',
              hideHeaderClose: false,
              centered: true
            }).then(async (value) => {
              const data = {
                actionUser: window.localStorage.getItem('user-name'),
                client_id: this.addCampaign.client_id,
                campaign_name: this.addCampaign.campaign_name,
                product_link: this.addCampaign.product_link,
                gender: this.addCampaign.gender ? this.addCampaign.gender.join() : null,
                category: this.addCampaign.category ? this.addCampaign.category.join() : null,
                language: this.addCampaign.language ? this.addCampaign.language.join() : null,
                budget: this.addCampaign.budget,
                min_follower: this.addCampaign.min_follower,
                max_follower: this.addCampaign.max_follower,
                promo_platform: this.addCampaign.promo_platform,
                campaign_type: this.addCampaign.campaign_type,
                content_type: this.addCampaign.content_type,
                campaign_desc: this.addCampaign.campaign_desc,
                deliverable: this.addCampaign.deliverable,
                start_date: this.addCampaign.start_date,
                end_date: this.addCampaign.end_date
              }
              if (value) {
                const response = await campaignAdd(data)
                if (response.succ) {
                  this.$bvToast.toast('Campaign added successfully', {
                    title: 'Added Campaign',
                    variant: 'success',
                    autoHideDelay: 1000,
                    solid: true
                  })
                  this.getData()
                  this.resetCampaign()
                  this.$refs.addModal.hide()
                } else {
                  this.$bvToast.toast(response.msg, {
                    title: 'Add Campaign error!',
                    variant: 'danger',
                    solid: true
                  })
                }
              }
            }).catch(() => {
              console.log('cancel add.')
            })
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
  }
}
</script>

<style datad>
  .name {
    color: maroon;
  }
  .card-body >>> .table {
    font-size: 14px;
  }
  .table td p {
    margin: 0 0 0 10px;
    line-height: 18px;
  }
  .table td a.meta {
    text-decoration: none;
    color: #4c62e3;
    text-transform: uppercase;
  }
  .table td img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
  }
  .table td p small {
    color: #f54153;
    font-size: 13px;
    text-transform: capitalize;
  }
  .innerdiv {
    color: #4c62e3;
    cursor: pointer !important;
  }
  .infocard .action {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .infocard h6 span {
    line-height: normal;
    font-size: 13px;
  }
  .infocard h6 {
    margin: 0 0 3px;
  }
  .infocard h6,
  .infocard h6 span.title {
    font-size: 16px;
    line-height: 16px;
    text-transform: capitalize;
    transition: all 0.3s;
    color: #4c62e3;
  }
  .infocard h6 span.title {
    margin: 0 10px 0 0;
    cursor: pointer;
  }
  .infocard h6 span.title:hover {
    color: #58a0ed !important;
  }
  .infocard .spinner-grow {
    position: relative;
    top: -3px;
    margin: 0 2px 0 0;
  }
  .infocard p {
    margin: 0;
    line-height: normal;
    font-size: 14px;
  }
  .infocard p span.label {
    font-size: 13px;
    margin: 0 5px 0 0;
  }
  .infocard strong {
    line-height: normal;
    font-size: 14px;
  }
  .infocard p small {
    color: #818588;
    margin: 0 15px 0 0;
    font-size: 13px;
  }
  .infocard p span {
    font-size: 13px;
  }
  .infocard .badge {
    font-size: 11px;
    padding: 3px 6px;
    margin: 0 15px 0 0;
  }
  .client:hover {
    cursor: pointer;
    color: #4c62e3;
  }
</style>
