import request from './index'

export const campaignSelect = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/campaignSelect',
    params: data
  })
}

export const brandSelect = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/brandSelect',
    params: data
  })
}

export const othercampaigninflu = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/othercampaigninflu',
    params: data
  })
}

export const assignYt = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/assignYt',
    data: data
  })
}
export const assigninfluCampaign = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/assigninfluCampaign',
    data: data
  })
}

export const assignInsta = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/assignInsta',
    data: data
  })
}

export const campaignList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/list',
    params: data
  })
}

export const campaignStatus = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/status',
    data: data
  })
}

export const camPostStatus = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/camPostStatus',
    data: data
  })
}

export const campaignDelete = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/delete',
    data: data
  })
}

export const campaignAdd = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/add',
    data: data
  })
}

export const campaignUpdate = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/update',
    data: data
  })
}

export const brandinflustatus = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/brandinflustatus',
    data: data
  })
}

export const influcampaignstatus = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/influcampaignstatus',
    data: data
  })
}

export const brandinfluList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/brandinfluList',
    params: data
  })
}
export const influcampaignList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/influcampaignList',
    params: data
  })
}
export const postDelete = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/deletePost',
    data: data
  })
}
export const postUpdate = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/updatPost',
    data: data
  })
}
export const postAdd = (data) => {
  return request({
    method: 'POST',
    url: '/campaign/addpost',
    data: data
  })
}

export const campaignPost = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/postList',
    params: data
  })
}
export const youtubeList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/youtubeList',
    params: data
  })
}

export const instaList = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/instaList',
    params: data
  })
}
export const postdata = (data) => {
  return request({
    method: 'POST',
    url: '/instagram/postdata',
    data: data
  })
}
export const postApprove = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/post/approve',
    data: data
  })
}
export const postRemark = (data) => {
  return request({
    method: 'GET',
    url: '/campaign/post/remark',
    params: data
  })
}
export const assignInfluencer = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/channel/assignInfluencer',
    data: data
  })
}
export const campaignChannelDelete = (data) => {
  return request({
    method: 'PUT',
    url: '/campaign/deleteChannel',
    data: data
  })
}
